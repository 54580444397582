import React, { useEffect, useState } from "react";
import config from "../../../config.json";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tab, Tabs } from "@mui/material";

const RankingsPage = ({ serverId }) => {
  const [state, setState] = useState({
    rankType: "NONE",
    rankList: [],
  });

  const apiEndPoint = config.apiEndPoint + "statistics/";
  const [rankType, setRankType] = useState();
  useEffect(async () => {
    const results = await axios.get(
      `${apiEndPoint}${rankType ?? ""}` + "/" + serverId,
    );

    setState({
      rankType: rankType,
      rankList: results.data,
    });
  }, [rankType]);

  const { rankList } = state;
  const navigateTo = (rankType) => {
    setRankType(rankType);
  };
  const [value, setValue] = React.useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigateTo(newValue);
  };
  useEffect(() => {
    handleChange(null, 1);
  }, []);

  const fetchClass = (job) => {
    if (job >= 10 && job <= 15) return "Trojan";
    if (job >= 20 && job <= 25) return "Warrior";
    if (job >= 40 && job <= 45) return "Archer";
    if (job >= 100 && job <= 105) return "Taoist";
    if (job >= 130 && job <= 135) return "WaterTaoist";
    if (job >= 140 && job <= 145) return "FireTaoist";
  };
  const rankingButtons = [
    { key: 1, value: "Level" },
    { key: 2, value: "Gold" },
    { key: 3, value: "Nobility" },
    { key: 4, value: "PkPoints" },
    { key: 5, value: "VirtuePoints" },
    { key: 99, value: "KOs" },
  ];
  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
        className={"mb-4 overflow-y-auto max-h-52"}
      >
        {rankingButtons.map((rank) => (
          <Tab value={rank.key} label={rank.value} />
        ))}
      </Tabs>

      <div>
        {rankList.length == 0 && <h3>No ranks available</h3>}
        {rankList.length !== 0 && rankType !== 99 && (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "60vh" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        minWidth: 100,
                        fontWeight: "bold",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 30,
                        fontWeight: "bold",
                      }}
                    >
                      Level
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 150,
                        fontWeight: "bold",
                      }}
                    >
                      {rankType === 1 && <>Level</>}
                      {rankType === 2 && <>Gold</>}
                      {rankType === 3 && <>Donation</>}
                      {rankType === 4 && <>PKPoints</>}
                      {rankType === 5 && <>VirtuePoints</>}
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 50,
                        fontWeight: "bold",
                      }}
                    >
                      Class
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rankList.map((p) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={p.name}
                      >
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.level}</TableCell>
                        {rankType === 1 && <TableCell>{p.level}</TableCell>}
                        {rankType === 2 && (
                          <TableCell>
                            {Number(p.gold ?? 0).toLocaleString()}
                          </TableCell>
                        )}
                        {rankType === 3 && (
                          <TableCell>
                            {Number(p.nobility ?? 0).toLocaleString()}
                          </TableCell>
                        )}{" "}
                        {rankType === 4 && (
                          <TableCell>
                            {Number(p.pkPoints ?? 0).toLocaleString()}
                          </TableCell>
                        )}{" "}
                        {rankType === 5 && (
                          <TableCell>
                            {Number(p.virtuePoints ?? 0).toLocaleString()}
                          </TableCell>
                        )}
                        <TableCell>{fetchClass(p.job)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        {rankList.length !== 0 && rankType === 99 && (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "60vh" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        minWidth: 100,
                        fontWeight: "bold",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 30,
                        fontWeight: "bold",
                      }}
                    >
                      KO
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rankList.map((p) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={p.name}
                      >
                        <TableCell>{p.name}</TableCell>
                        <TableCell>
                          {Number(p.param ?? 0).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </div>
    </Box>
  );
};

export default RankingsPage;
