import React from "react";
import { Container } from "react-bootstrap";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Box, Button } from "@mui/material";
import config from "../../config.json";
const DownloadsPage = () => {
  const navigate = (url) => {
    window.location.href = url;
  };

  return (
    <Box className={"pb-5"}>
      <IconBreadcrumbs name={"Downloads"} />
      <Container className={"text-center"}>
        <p className={"font-semibold text-lg"}>Game Client Download</p>
        <div className={"flex justify-center"}>
          <p className={"text-left max-w-96"}>
            You can download and install Classic Conquer using the following
            download link. Once downloaded, simply install and run the game from
            the new desktop shortcut.
          </p>
        </div>
        <Button
          variant="contained"
          color="primary"
          className="feature__btn mb-2"
          style={{ borderRadius: "5px" }}
          onClick={() => navigate(config.DOWNLOAD_LINK)}
          size={"large"}
        >
          <div className={"flex justify-center items-center gap-x-2"}>
            <i className="fa fa-windows fa-lg"></i>
            Download
          </div>
        </Button>
      </Container>
    </Box>
  );
};
export default DownloadsPage;
