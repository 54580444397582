import React from "react";
import Form from "../common/form";
import { Container } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import AuthService from "../../services/authService";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config.json";

class ForgotPassword extends Form {
  apiEndPoint = config.apiEndPoint + "users/forgotpass";
  recaptchaRef = React.createRef();
  schema = Yup.object().shape({
    username: Yup.string()
      .required("User is a required field")
      .min(4, "Minimum is 4 characters")
      .max(15, "Maximum is 15 digits"),
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid Email"),
  });

  async doSubmit(values, captchaResponse) {
    return axios
      .post(this.apiEndPoint, { ...values, captchaResponse: captchaResponse })
      .then((result) => result.data)
      .then((result) => {
        console.log(result);
        const token = result?.jwt;

        if (!token) {
          toast.error("Something went wrong.");
          return;
        }
        AuthService.storeJWT(token);
        toast.success("Login successful.. Redirecting");
        setTimeout(() => {
          window.location = "/";
        }, 1500);
      })
      .catch((ex) => {
        const errors = ex?.response?.data?.errors;
        if (errors) {
          const p = Object.entries(errors);
          p.forEach((e) => {
            const err = e[1][0];
            toast.error(err);
          });
        }
      })
      .then(() => {
        this.recaptchaRef.current.reset();
      });
  }

  render() {
    return (
      <>
        <IconBreadcrumbs name={"Forgot Password"} />
        <Container className={"pb-5"}>
          <div className={"flex justify-center "}>
            <div className={"max-w-full lg:w-2/3 "}>
              <p className={"text-lg font-bold"}>Forgot your password?</p>
              <p>
                Change your password in three easy steps. This helps to keep
                your new password secure.
              </p>
              <p>1. Fill in your username and email below.</p>
              <p>2. We'll email you a recovery link.</p>
              <p>
                {" "}
                3. Use the link to change your password on our secure website.
              </p>
              <div className={"border-1.5 p-12"}>
                <Formik
                  validationSchema={this.schema}
                  initialValues={{ username: "", email: "" }}
                  onSubmit={(values) => {
                    // Alert the input values of the form that we filled
                    this.doSubmit(values, this.recaptchaRef.current.getValue());
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Box>
                      <form noValidate onSubmit={handleSubmit}>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Username</p>
                          <TextField
                            autoComplete="current-password"
                            type="username"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            placeholder="Enter username here"
                            id="username"
                            className="form-control"
                          />
                          {errors.username && touched.username && (
                            <div className="alert alert-danger mt-3">
                              {errors.username}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Email</p>
                          <TextField
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Enter email"
                            id="email"
                            className="form-control"
                          />
                          {errors.email && touched.email && (
                            <div className="alert alert-danger mt-3">
                              {errors.email}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey={config.RECAPTCHA_SITE_KEY}
                          ></ReCAPTCHA>
                        </Box>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Send Recovery link
                        </Button>
                      </form>
                    </Box>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default ForgotPassword;
