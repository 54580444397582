import React, { useRef } from "react";

import useSlider from "../../../hooks/useSlider";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import config from "../../../config.json";

const Slider = ({ images }) => {
  const slideImage = useRef(null);
  const slideText = useRef(null);
  const slideTitle = useRef(null);
  const navigate = useNavigate();
  const { goToPreviousSlide, goToNextSlide } = useSlider(
    slideImage,
    slideText,
    slideTitle,
    images,
  );
  setInterval(() => goToNextSlide(), 5000);
  return (
    <div className="slider" ref={slideImage}>
      <div className="slider--content">
        <button onClick={goToPreviousSlide} className="slider__btn-left">
          <i className="fa fa-angle-left"></i>
        </button>
        <div className="slider--feature">
          <h1 ref={slideTitle} className="feature--title"></h1>
          <p ref={slideText} className="feature--text"></p>
          <div className={"display-block md:flex gap-x-2 justify-center"}>
            <Button
              variant="contained"
              color="error"
              className="feature__btn mb-2"
              style={{ borderRadius: "5px" }}
              onClick={() => navigate("/downloads")}
              size={"large"}
            >
              <div className={"flex justify-center items-center gap-x-2"}>
                <i className="fa fa-windows fa-lg"></i>
                DOWNLOAD
              </div>
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="feature__btn mb-2"
              style={{ borderRadius: "5px" }}
              size={"large"}
              onClick={() => navigate("/user/register")}
            >
              <div className={"flex justify-center items-center gap-x-2"}>
                <i className="fa fa-user fa-2x"></i>
                Create Account
              </div>
            </Button>
            <Button
              variant="contained"
              color="success"
              className="feature__btn mb-2"
              onClick={() => navigate(config.DISCORD_LINK)}
              size={"large"}
              style={{ borderRadius: "5px" }}
            >
              <div className={"flex justify-center items-center gap-x-2"}>
                <i className="fa fa-mobile fa-2x"></i>
                Discord
              </div>
            </Button>
          </div>
        </div>
        <button onClick={goToNextSlide} className="slider__btn-right">
          <i className="fa fa-angle-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Slider;
